<template>
  <div>
    <warehouse
      @updated="
        (_) => {
          dialog = _;
          getWarehouses();
        }
      "
      :dialog="dialog"
    />
    <v-row>
      <v-col cols="12" md="12">
        <v-card outlined :loading="loading" class="transparent">
          <v-card-title class="text-h4 font-weight-bold mb-2">
            {{ $t("warehouses") }}
          </v-card-title>
          <!-- <v-card-subtitle>
                    Click on Warehouse card to manage it and see its details
                </v-card-subtitle> -->
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <template v-if="loading">
                <v-col v-for="i in 6" :key="i" cols="12" md="4">
                  <v-skeleton-loader light type="card"></v-skeleton-loader>
                </v-col>
              </template>
              <template v-if="!loading">
                <v-col
                  v-for="warehouse in warehouses"
                  :key="warehouse.id"
                  cols="12"
                  md="4"
                  lg="4"
                >
                  <v-card
                    @click="openWarehouse(warehouse)"
                    outlined
                    class="company-card cursor-pointer"
                  >
                    <v-card-title>
                      <h3>{{ warehouse.name }}</h3>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px !important">
                      <div class="d-flex justify-space-around">
                        <p
                          class="text-center font-weight-font-weight-medium  text-h6"
                        >
                          {{ $t("product-count") }} :
                          {{ warehouse.productCount }}
                        </p>
                      </div>
                    </v-card-text>
                    <v-card-text
                      style="padding-top: 0px !important"
                      class="d-flex justify-center mt-0"
                    >
                      <v-btn @click="openWarehouse(warehouse)" text>{{
                        $t("click-to-see-detials")
                      }}</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
              <v-col cols="12" md="4">
                <v-card
                  @click="dialog = !dialog"
                  style="height: 100%; background-color: transparent"
                  flat
                  class="cursor-pointer"
                >
                  <v-card-text
                    class="
                      d-flex
                      justify-center
                      flex-column
                      align-content-center
                    "
                  >
                    <v-icon size="100" color=""> mdi-plus-circle </v-icon>
                    <span
                      class="
                        text-center text--white text-h5
                        font-weight-bold
                        mt-5
                      "
                    >
                      {{ $t('add-a-new-warehouse') }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Warehouse from "@/views/modals/Warehouse.vue";
export default {
  components: {
    Warehouse,
  },
  data() {
    return {
      loading: false,
      warehouses: [],
      dialog: false,
    };
  },
  methods: {
    getWarehouses() {
      this.loading = true;

      this.$http
        .get("/Warehouses", {
          params: {
            CompanyId: this.$store.state.company.id,
          },
        })
        .then((ware) => {
          this.warehouses = ware.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
    async openWarehouse(item) {
      this.$store.commit("warehouses/updateWarehouse", item);
      // router push to dashboard-warehouse
      this.$router.push({
        name: "dashboard-warehouse",
      });
    },
  },

  watch: {
    "options.Search"() {
      this.getWarehouses();
    },
  },
  async created() {
    try {
      this.getWarehouses();
    } catch {
      this.$store.commit("UPDATE_SNACKBAR", true);
    }
  },
};
</script>
